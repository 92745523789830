<template>
  <div class="top-banner">
    <div class="bg"></div>
    <div class="ban">
      <div class="title-big">American Delivery Service </div>
      <div class="title-small">
        <div>Experience hassle-free, timely delivery of your products.</div>
        <div>We allow you to add at most 200 packages in one order.</div>
      </div>
    </div>
  </div>
  <div class="des">
    <a-typography-title>
      Logistics Warehousing
    </a-typography-title>
    <a-typography-title :heading="5"><i>"Rome wasn't built in a day"</i></a-typography-title>
    <a-typography-paragraph type="secondary">
      ADS always takes time and effort to build their warehouse through a range of processes
      and procedures designed to ensure that inventory is stored, managed, and shipped efficiently and effectively.
    </a-typography-paragraph>
    <a-typography-title :heading="5">Receiving</a-typography-title>
    <a-typography-paragraph type="secondary">
      When goods arrive at the warehouse, they are checked in and logged into the inventory management
      system.
      This includes checking for any damage or discrepancies between the received goods and the original order.
    </a-typography-paragraph>
    <a-typography-title :heading="5">Storage</a-typography-title>
    <a-typography-paragraph type="secondary">
      Goods are stored in the warehouse according to their specific requirements, such as temperature,
      humidity,
      and fragility. This involves using appropriate storage methods, such as shelving, racking, or pallets, to maximize
      space and accessibility.
    </a-typography-paragraph>
    <a-typography-title :heading="5">Inventory management</a-typography-title>
    <a-typography-paragraph type="secondary">
      An inventory management system is used to track the location and quantity of all goods in the
      warehouse. This allows for easy retrieval of products when they are needed and helps prevent stockouts or
      overstocking.
    </a-typography-paragraph>
    <a-typography-title :heading="5"> Order fulfillment</a-typography-title>
    <a-typography-paragraph type="secondary">
      When an order is received, our warehouse staff will retrieve the necessary products from
      inventory
      and prepare them for shipment. This includes packaging, labeling, and palletizing goods as needed.
    </a-typography-paragraph>
    <a-typography-title :heading="5">Shipping</a-typography-title>
    <a-typography-paragraph type="secondary">
      Once the order is prepared, it is shipped to the customer or the next stage of the supply chain.
      This
      involves using appropriate shipping methods, such as cars, vans, or trucks, and ensuring that the products are
      properly packaged and labeled.
    </a-typography-paragraph>
    <a-typography-title :heading="5">Maintenance</a-typography-title>
    <a-typography-paragraph type="secondary">
      The warehouse and its equipment are regularly maintained to ensure that they are in good working
      order.
      Futhermore, it helps identify potential problems before they become major issues. Inspect the warehouse floor,
      walls,
      ceiling, lighting, and equipment for signs of wear and damage. Make a list of any necessary repairs.
    </a-typography-paragraph>
    <a-typography-title :heading="5">Security</a-typography-title>
    <a-typography-paragraph type="secondary">
      Our warehouse have a range of security measures in place, such as cameras, alarms, and restricted
      access,
      ensuring that your goods are safe and secure. We also provide guidance to our staff on warehouse security
      measures,
      emergency response procedures, and incident reporting to ensure they are aware of potential risks and can respond
      appropriately.
    </a-typography-paragraph>
    <a-typography-title :heading="5">
      Staff training
    </a-typography-title>
    <a-typography-paragraph type="secondary">
      Training and development programs can help warehouse staff to work efficiently and safely, reducing
      the risk of accidents and product damage. Training programs may cover topics such as material handling, equipment
      operation, and safety procedures.
    </a-typography-paragraph>
    <a-typography-title :heading="6" style="margin-top:60px;font-weight: 700;">
      By following these key steps, we can ensure that the warehouse operations run smoothly and efficiently, leading to
      better customer satisfaction, reduced costs, and improved overall performance.
    </a-typography-title>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku1.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

h1.arco-typography {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.about-us {
  text-align: center;
  padding-top: 100px;
  font-weight: 300;
  font-size: 56px;
  line-height: 53px;
  color: #333333;
}

:deep(.arco-layout-content) {
  background-color: #fff !important;
}

.des {
  width: 1200px;
  margin: 68px auto;
  font-family: "PingFang SC";
}
</style>
